.progress {
  background: rgba(255, 255, 255, 0.1);
  justify-content: flex-start;
  border-radius: 100px;
  align-items: center;
  position: absolute;
  padding: 0;
  display: flex;
  width: 100%;
  padding: 0 20px;
}

.progressValue {
  animation: load 500ms normal infinite;
  box-shadow: 0 10px 40px -10px rgb(112, 112, 112);
  border-radius: 100px;
  background: #192357;
  height: 2px;
  position: relative;
  width: 0;
  z-index: 999;
}

@keyframes load {
  0% {
    width: 0;
  }

  100% {
    width: 100%;
  }

}