$primary-blue: #192357;
$search-button-color: #5577A0;
$search-placeholder-color: #73778f;
$dropdown-hover-color: #efefef;
$color: red;

.lowCase {
  text-transform: lowercase;
}

.mobileHeader {
  overflow-y: hidden;
}


.mobileMenu {
  position: fixed;
  top: 0;
  right: 0;
  width: 100%;
  height: 72px;
  background-color: #fff;
  justify-content: space-between;
  box-shadow: 0px 3px 6px #1923571a;
  z-index: 80;

  .logoSmall {
    position: absolute;
    top: 11px;
    left: 26px;
  }

  .userNavMobile {
    position: absolute;
    right: 0;
    display: flex;
    align-items: center;
    margin-top: 11px;
    padding-right: 95px;
    padding-left: 20px;
    z-index: -1;
    width: calc(100vw - 90px);
    height: 49px;
    background-color: $primary-blue;
    border-bottom-left-radius: 20px;

    .userLinkMob {
      margin-bottom: 6px;
      height: 19px;
      width: 22px;
      margin-right: 30px;
    }

    .userLinkMob:hover:before {
      height: 49px;
      content: "";
      position: absolute;
      top: 0px;
      display: block;
      width: calc(60px);
      margin-left: -20px;
      border-top-right-radius: 20px;
      border-bottom-left-radius: 20px;
      z-index: -1;
      background-color: $search-button-color;
    }

    .userLinkMob:nth-child(2):before {
      width: 57px;
      margin-left: -15px;
    }

    .userLinkMob:nth-child(3):before {
      width: 57px;
      margin-left: -15px;
    }

    .sideMenuIcon {
      cursor: pointer;
      position: fixed;
      top: 19px;
      right: 26px;
    }
  }
}

// @media only screen and (max-width: 900px) {
//   .headerComponent {
//     display: none !important;
//   }

//   .mobileMenu {
//     display: flex;
//   }

//   .sideMenu {
//     display: none;
//   }
// }

@media only screen and (max-width: 1085px) {
  .header {
    .userLink {
      span.toHideLinks {
        display: none;
      }
    }

    .userLink:hover:before {
      width: 42px !important;
      margin-left: -7px !important;
    }

  }
}

.headerComponent {
  display: flex;
  position: fixed;
  top: 0;
  flex-direction: row;
  justify-content: center;
  justify-items: center;
  z-index: 80;
  width: 100%;
  background-color: #fff;
  box-shadow: 0px 3px 6px #1923571a;

  .headerSpaceFiller {
    position: absolute;
    height: 62px;
    right: 0;
    width: 40vw;
    margin-top: 34px;
    background-color: $primary-blue;
  }

  .header {
    width: 92%;
    display: flex;
    flex-direction: column;
    height: 150px;
    z-index: 5;

    .topNav {
      font-size: 12px;
      line-height: 14px;
      color: $primary-blue;
      display: flex;
      justify-content: flex-end;
      align-items: center;

      .topNavContacts {
        display: flex;

        .phone,
        .email {
          margin-top: 10px;
          display: flex;
          flex-direction: row;
          margin-left: 26px;
        }

        .phone svg {
          margin-top: -2px;
        }

        svg {
          margin: 0 10px;
          width: 17px;
        }

        a {
          padding-bottom: 10px;
        }
      }

      .topNavLanguages {
        margin-left: 38px;

        span {
          margin-left: 16px;
        }
      }
    }

    .navbar {
      display: flex;
      width: 100%;

      .logo {
        margin-top: -10px;
        margin-right: 55px;
      }

      .logoSmall {
        display: none;
      }

      .mainNav {
        width: 100%;
        color: #fff;
        display: flex;
        align-items: flex-end;
        background-color: $primary-blue;
        height: 62px;
        border-bottom-left-radius: 20px;

        .nav {
          font-size: 18px;
          width: 100%;
          display: flex;
          vertical-align: bottom;
          overflow: hidden;

          .productsLinkButton {
            position: relative;
            z-index: 0;
            margin-left: 40px;
          }

          .servicesLinkButton {
            position: relative;
            z-index: 0;
            margin-left: 60px;
          }

          .informationLinkButton {
            position: relative;
            z-index: 0;
            margin-left: 60px;
          }

          .productsLinkButton:hover:before {
            height: 62px;
            padding-left: 40px;
            content: "";
            left: -40px;
            position: absolute;
            display: block;
            width: 100px;
            border-top-right-radius: 20px;
            border-bottom-left-radius: 20px;
            z-index: -1;
            top: -20px;
            background-color: $search-button-color;
          }

          .informationLinkButton:hover:before {
            height: 62px;
            content: "";
            position: absolute;
            display: block;
            width: 186px;
            margin-left: -35px;
            top: -18px;
            border-top-right-radius: 20px;
            border-bottom-left-radius: 20px;
            z-index: -1;
            background-color: $search-button-color;
          }

          .servicesLinkButton:hover:before {
            height: 62px;
            content: "";
            position: absolute;
            display: block;
            width: 171px;
            margin-left: -35px;
            top: -18px;
            border-top-right-radius: 20px;
            border-bottom-left-radius: 20px;
            z-index: -1;
            background-color: $search-button-color;
          }

          span {
            margin-left: 50px;
            margin-right: 10px;
            z-index: 80;
            line-height: 62px;
          }
        }
      }

      .userNav {
        display: flex;
        justify-content: space-between;
        z-index: 0;

        .userLink:nth-child(1):hover:before {
          width: 115px;
          margin-left: -17px;
        }

        .userLink:nth-child(3):hover:before {
          width: 120px;
          margin-left: -13px;
        }

        .userLink:hover:before {
          height: 62px;
          content: "";
          position: absolute;
          display: block;
          width: 123px;
          margin-left: -11px;
          border-top-right-radius: 20px;
          border-bottom-left-radius: 20px;
          z-index: -1;
          background-color: $search-button-color;
        }

        .userLinkMenuHidden {
          margin-top: 4px;
          display: none;
          margin-left: 60px;
        }

        .userLink {
          display: flex;
          align-items: center;
          margin-bottom: 16px;
          margin-left: 2vw;
          z-index: 99;

          span.toHideLinks {
            margin-left: 14px;
            line-height: 11px;
            font-size: 10px;
          }
        }
      }
    }
  }
}

// ///////// ///// ////// search bar
.fatArrow {
  margin-right: 10px;
  margin-left: 30px;
}

.listxxx {
  color: $primary-blue;
  position: relative;
  margin-top: 200px;
  width: calc(326px + 120px);
  z-index: 1;

  span {
    padding-left: 40px;
  }
}

.list2lvl {
  position: absolute;
  left: -600px;
}